import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import BottomNavbar from "../../BottomNavbar/BottomNavbar";
import AchievmentModal from "./AchievmentModal/AchievmentModal";

import {
  AchievmentKeys,
  achievmentImages,
} from "../../../constants/achievments";
import { useAppSelector } from "../../../hooks/redux";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import styles from "./AchievmentsList.module.scss";

const paskalToNormal = (text: string) =>
  text.replace(/([a-z])([A-Z])/g, "$1 $2");

const AchievmentsList = () => {
  const achievments = useAppSelector((state) => state.achievments.achievments);
  const [openedAchievmentId, setOpenedAchievmentId] = useState<number | null>(
    null
  );
  const [openedAchievmentKey, setOpenedAchievmentKey] =
    useState<AchievmentKeys | null>(null);
  const [openedAchievmentIndex, setOpenedAchievmentIndex] = useState<
    number | null
  >(null);
  const [modalOpened, setModalOpened] = useState(false);

  if (!achievments) return null;

  const openedAchievment =
    (openedAchievmentId &&
      Object.values(achievments)
        .flat()
        .find((item) => item.id === openedAchievmentId)) ||
    null;

  return (
    <main className={styles.achievmentsList}>
      <h2 className={styles.achievmentsList__title}>Achievements</h2>
      <section className={styles.achievmentsList__main}>
        <div className={styles.achievmentsList__cards}>
          {Object.keys(achievments).map((key, index) => {
            const item = key as AchievmentKeys;

            return (
              <div key={index} className={styles.achievmentsList__col}>
                <div className={styles.achievmentsList__colHead}>
                  <h5 className={styles.achievmentsList__colTitle}>
                    {paskalToNormal(item)}
                  </h5>
                  <p className={styles.achievmentsList__cardsCount}>
                    {achievments[item].length} cards
                  </p>
                </div>
                <Swiper
                  slidesPerView={5}
                  className={styles.achievmentsList__cardsList}
                >
                  {achievments[item].map((card, cardIndex) => {
                    const img = achievmentImages[item][+!card.received];

                    return (
                      <SwiperSlide>
                        <button
                          onClick={() => {
                            setOpenedAchievmentId(card.id);
                            setOpenedAchievmentKey(item);
                            setOpenedAchievmentIndex(cardIndex);
                            setModalOpened(true);
                          }}
                          className={styles.achievmentsList__card}
                          key={cardIndex}
                        >
                          <div
                            className={styles.achievmentsList__cardInner}
                            key={index}
                          >
                            <ImageWebp
                              srcSet={img.srcSet}
                              src={img.src}
                              alt="achievment"
                              className={`${styles.achievmentsList__cardImg} ${
                                !card.received
                                  ? styles.achievmentsList__cardImg_disabled
                                  : ""
                              }`}
                            />
                            <h6 className={styles.achievmentsList__cardTitle}>
                              {card.short_title}
                            </h6>
                          </div>
                        </button>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            );
          })}
        </div>
        <BottomNavbar isBottomVisible={false} />
      </section>
      <AchievmentModal
        achievmentIndex={openedAchievmentIndex}
        achievmentKey={openedAchievmentKey}
        achievment={openedAchievment}
        open={modalOpened}
        onClose={() => setModalOpened(false)}
      />
    </main>
  );
};

export default AchievmentsList;
