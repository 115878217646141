import React, {useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useLocation} from "react-router-dom";
import {calculateAndInitUserData, setEnergy} from "../../store/slices/profileSlice";
import {useTelegram} from "../../hooks/useTelegram";
import Marquee from "react-fast-marquee";

import AppRouter from "../../router/AppRouter";
import BottomNavbar from "../BottomNavbar/BottomNavbar";

import {
  achievmentsPagePath,
  boostPagePath,
  frensPagePath,
  leaguePagePath,
} from "../../router/path";
import {starsImg} from "../../assets/images";
import styles from "./App.module.scss"
import {useImageLoader} from "../../hooks/useImageLoader";
import QRScreen from "../QRScreen/QRScreen";
import TutorialSlider from "../TutorialSlider/TutorialSlider";
import ErrorScreen from "../ErrorScreen/ErrorScreen";
import Loader from "../Loader/Loader";
import NewAchievmentModal from "../NewAchevmentModal/NewAchevmentModal";
import DailyCheckin from '../DailyCheckin/DailyCheckin';

export const App = () => {
    const dispatch = useAppDispatch()
    const tg = useTelegram()
    const location = useLocation()
    const imagesLoading = useImageLoader()

    const energy = useAppSelector(state => state.profile.user.energy)
    const fullEnergy = useAppSelector(state => state.profile.user.fullEnergy)
    const isSwiping = useAppSelector(state => state.profile.isSwiping)
    const getUserError = useAppSelector(state => state.profile.getUserError)

    const isMobile = true //!!(tg?.platform && !['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(tg?.platform))

    const intervalRef = useRef<undefined | NodeJS.Timer>(undefined)
    const withoutPaddingPages = [boostPagePath, achievmentsPagePath];
    const isWithoutPadding = withoutPaddingPages.includes(location.pathname);
    const isBottomVisible = location.pathname === boostPagePath;
    const isBgHidden = location.pathname === frensPagePath
    const isNavbarHidden = location.pathname === achievmentsPagePath;
    const shouldShowQRScreen = false // !isMobile || !tg?.initDataUnsafe?.user;

    useEffect(() => {
        if (!tg) return;
        // open fullscreen
        tg.expand()

        if (!isMobile) return;
        // disable scroll on mobile
        tg.setHeaderColor('#010101');
        const overflow = 100
        document.body.style.overflowY = 'hidden'
        document.body.style.marginTop = `${overflow}px`
        document.body.style.height = window.innerHeight + overflow + "px"
        document.body.style.paddingBottom = `${overflow}px`
        window.scrollTo(0, overflow)
        tg.ready()

        if (!tg.initDataUnsafe?.user) return;
        const {
            id,
            first_name,
            last_name,
            username,
            language_code,
        } = tg.initDataUnsafe?.user

        const payload = {
            id,
            first_name,
            last_name: last_name || "",
            username: username || "",
            language_code: language_code || "",
        }


        dispatch(calculateAndInitUserData(payload))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        // update energy per sec.
        if (energy < fullEnergy && !isSwiping) {
            if (intervalRef.current) clearInterval(intervalRef.current)
            intervalRef.current = setInterval(() => {
                dispatch(setEnergy(energy + 1))
            }, 1000)
        } else {
            clearInterval(intervalRef.current)
            intervalRef.current = undefined
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [energy, fullEnergy, isSwiping]);

    return (
        <>
          <div
            className={`${styles.app} ${
              location.pathname === leaguePagePath ? styles.app_topGradient : ""
            }`}
          >
            {!isBgHidden && (
              <div className={styles.app__bg}>
                <Marquee
                  play={isSwiping}
                  speed={200}
                  className={styles.app__marque}
                  direction={"down"}
                >
                  <img src={starsImg} alt="" className={styles.app__starsImg} />
                  <img src={starsImg} alt="" className={styles.app__starsImg} />
                </Marquee>
              </div>
            )}
            {shouldShowQRScreen 
            ? (
              <QRScreen />
            ) : (
              <>
                {/* <Loader imagesLoading={imagesLoading} /> */}
                <TutorialSlider imagesLoading={imagesLoading || getUserError} />
                <DailyCheckin imageLoading={imagesLoading || getUserError} />
                <NewAchievmentModal imageLoading={imagesLoading || getUserError} />
                <div
                  className={
                    `${styles.app__container} ` +
                    `${imagesLoading ? styles.app__container_hidden : ""} ` +
                    `${isWithoutPadding ? styles.app__container_full : ""}`
                  }
                >
                  {getUserError ? (
                    <ErrorScreen />
                  ) : (
                    <>
                      <div
                        className={`${styles.app__main} ${
                          isBottomVisible ? styles.app__main_visible : ""
                        }`}
                      >
                        <AppRouter />
                      </div>
                      {!isNavbarHidden && (
                      <div className={isWithoutPadding ? "container" : ""}>
                        <BottomNavbar isBottomVisible={isBottomVisible} />
                      </div>
                        )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      );
    };
    