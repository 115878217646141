import { useEffect, useRef, useState } from "react";
import { Swiper as SwiperType } from "swiper/types";
import { arrowIconImg, arrowIconWebpImg } from "../../assets/images";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../providers/TransitionProvider";
import AchievmentImageFrame from "../AchievmentsPage/AchievmentImageFrame/AchievmentImageFrame";
import MainBtn from "../layout/MainBtn/MainBtn";

import Lottie from "lottie-react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { fireworksLottie } from "../../assets/lotties";
import { AchievmentKeys, achievmentImages } from "../../constants/achievments";
import { getLSItem, setLSItem } from "../../helpers/localStorage";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  hideNewAchievments,
  showNewAchievments,
} from "../../store/slices/achievmentsSlice";
import { lsProps } from "../../utils/lsProps";
import ImageWebp from "../layout/ImageWebp/ImageWebp";
import styles from "./NewAchevmentModal.module.scss";

type Props = {
  imageLoading: boolean;
};

const NewAchievmentModal: React.FC<Props> = ({ imageLoading }) => {
  const dispatch = useAppDispatch();
  const [activeIndex, setActiveIndex] = useState(0); // State to track active index

  const getLoading = useAppSelector((state) => state.profile.getUserLoading);

  const onboardingCompleted = useAppSelector(
    (state) => state.profile.user.onboardingCompleted
  );
  const dailyCheckinChecked = useAppSelector(
    (state) => state.profile.dailyCheckinChecked
  );
  const achievments =
    useAppSelector((state) => state.achievments.achievments) || null;
  const newReceivedAchievmentIds = useAppSelector(
    (state) => state.achievments.newAchievments
  );
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);

  useEffect(() => {
    if (
      !imageLoading &&
      !getLoading &&
      onboardingCompleted &&
      dailyCheckinChecked
    ) {
      getLSItem(lsProps.receivedAchievments, (_, item) => {
        const onShow = (payload: number[], storePayload: number[]) => {
          dispatch(showNewAchievments(payload));

          setLSItem(lsProps.receivedAchievments, storePayload);
        };

        if (!item) {
          // on open app first time
          onShow(receivedAchievmentIds, receivedAchievmentIds);
        } else {
          const storedAchievments = JSON.parse(item);
          const newAchievments = receivedAchievmentIds.filter(
            (item) => !storedAchievments.includes(item)
          );

          if (item && newAchievments.length) {
            onShow(newAchievments, receivedAchievmentIds);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageLoading, getLoading, onboardingCompleted, dailyCheckinChecked]);

  if (!achievments) return null;
console.log(achievments)
  const receivedAchievments = Object.keys(achievments)
    .map((achievmentKey) =>
      achievments[achievmentKey as AchievmentKeys].map((achievment, index) => ({
        ...achievment,
        achievmentKey,
        index,
      }))
    )
    .flat()
    .filter((item) => item.received);
  const receivedAchievmentIds = receivedAchievments.map((item) => item.id);
  const newReceivedAchievments = receivedAchievments.filter((item) =>
    newReceivedAchievmentIds.includes(item.id)
  );

  // if (!newReceivedAchievments.length) return null;
  const arrowsShowing = newReceivedAchievments.length > 1;

  return (
    <TransitionProvider
      inProp={
        !!(
          newReceivedAchievmentIds.length &&
          !getLoading &&
          onboardingCompleted &&
          dailyCheckinChecked
        )
      }
      type={TransitionStyleTypes.opacity}
      className={styles.newAchievmentModal}
    >
      <div className={styles.newAchievmentModal__lottieWrapper}>
        <Lottie
          animationData={fireworksLottie}
          loop={false}
          className={styles.newAchievmentModal__lottie}
        />
      </div>
      <div className={styles.newAchievmentModal__main}>
        <div className={styles.newAchievmentModal__sliderWrapper}>
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: styles.newAchievmentModal__arrowBtn_next,
              prevEl: styles.newAchievmentModal__arrowBtn_prev,
            }}
            onSlideChange={(swiper: SwiperType) =>
              setActiveIndex(swiper.activeIndex)
            }
            onBeforeInit={(swiper: SwiperType) => {
              if (typeof swiper.params.navigation === "object")
                swiper.params.navigation.nextEl = nextButtonRef.current;
              if (typeof swiper.params.navigation === "object")
                swiper.params.navigation.prevEl = prevButtonRef.current;
            }}
            slidesPerView={1}
          >
            {newReceivedAchievments.length &&
              newReceivedAchievments.map((item) => {
                const img = achievmentImages[item.achievmentKey][0];
                return (
                  <SwiperSlide key={item.id}>
                    <div className={styles.newAchievmentModal__slide}>
                      <h3 className={styles.newAchievmentModal__name}>
                        {item.title}
                      </h3>
                      <AchievmentImageFrame src={img.src} srcSet={img.srcSet} />
                    </div>
                  </SwiperSlide>
                );
              })}

            <div className={styles.newAchievmentModal__arrowsBlock}>
              <button
                className={`${styles.newAchievmentModal__arrowBtn} ${
                  styles.newAchievmentModal__arrowBtn_prev
                } ${
                  !arrowsShowing
                    ? styles.newAchievmentModal__arrowBtn_hidden
                    : ""
                }`}
                ref={prevButtonRef}
              >
                <ImageWebp
                  srcSet={arrowIconWebpImg}
                  src={arrowIconImg}
                  alt={"arrow"}
                  className={`${styles.newAchievmentModal__arrowIcon} ${styles.newAchievmentModal__arrowIcon_prev}`}
                />
              </button>
              <h5 className={styles.newAchievmentModal__subtitle}>
                You got an achievement
              </h5>
              <button
                className={`${styles.newAchievmentModal__arrowBtn} ${
                  styles.newAchievmentModal__arrowBtn_next
                } ${
                  !arrowsShowing
                    ? styles.newAchievmentModal__arrowBtn_hidden
                    : ""
                }`}
                ref={nextButtonRef}
              >
                <ImageWebp
                  srcSet={arrowIconWebpImg}
                  src={arrowIconImg}
                  alt={"arrow"}
                  className={styles.newAchievmentModal__arrowIcon}
                />
              </button>
            </div>
          </Swiper>
        </div>
        <div className={styles.newAchievmentModal__percentBlock}>
          <h6 className={styles.newAchievmentModal__percentTxt}>
            {newReceivedAchievments[activeIndex]?.people_count}%
          </h6>
          <p className={styles.newAchievmentModal__percentSubTxt}>
            users who have this achievement
          </p>
        </div>
      </div>
      <MainBtn
        onClick={() => {
          dispatch(hideNewAchievments());
        }}
        className={styles.newAchievmentModal__btn}
      >
        Close
      </MainBtn>
    </TransitionProvider>
  );
};

export default NewAchievmentModal;
