import { AppRoot, Modal } from "@xelene/tgui";
import { FC } from "react";

import {
  achievmentCrossIcon,
  achievmentCrossWebpIcon,
  achievmentTapPlaceImage,
  achievmentTapPlaceWebpImage,
} from "../../../../assets/images";
import {
  AchievmentKeys,
  achievmentImages,
} from "../../../../constants/achievments";
import { IAchievment } from "../../../../models/IAchievment";
import ImageWebp from "../../../layout/ImageWebp/ImageWebp";
import MainBtn from "../../../layout/MainBtn/MainBtn";
import AchievmentImageFrame from "../../AchievmentImageFrame/AchievmentImageFrame";
import styles from "./AchievmentModal.module.scss";

interface AchievmentModalProps {
  achievment: IAchievment | null;
  achievmentKey: AchievmentKeys | null;
  achievmentIndex: number | null;
  open: boolean;
  onClose: () => void;
}

const AchievmentModal: FC<AchievmentModalProps> = ({
  open,
  achievment,
  achievmentKey,
  achievmentIndex,
  onClose,
}) => {
  if (!achievment || !achievmentKey || achievmentIndex === null) return null;

  const img = achievmentImages[achievmentKey][+!achievment.received];

  return (
    <AppRoot>
      <Modal
        overlayComponent={<></>}
        onOpenChange={(open) => {
          if (!open) onClose();
        }}
        header={<></>}
        open={open}
        className={styles.achievmentModal}
      >
        <div className={styles.achievmentModal__container}>
          <button
            className={styles.achievmentModal__crossBtn}
            onClick={onClose}
          >
            <ImageWebp
              src={achievmentCrossIcon}
              srcSet={achievmentCrossWebpIcon}
              alt="cross"
              className={styles.achievmentModal__crossIconImg}
            />
          </button>
          <h2 className={styles.achievmentModal__title}>{achievment.title}</h2>
          <AchievmentImageFrame src={img.src} srcSet={img.srcSet} />
          <h6 className={styles.achievmentModal__recievedTxt}>
            {achievment.received ? "Received" : " "}
          </h6>

          <div className={styles.achievmentModal__info}>
            <div className={styles.achievmentModal__infoTxtBlock}>
              <h6 className={styles.achievmentModal__infoPercentTxt}>
                {achievment.people_count}%
              </h6>
              <p className={styles.achievmentModal__InfoUsersTxt}>
                users who have this achievement
              </p>
            </div>
            <ImageWebp
              src={achievmentTapPlaceImage}
              srcSet={achievmentTapPlaceWebpImage}
              alt="rocket"
              className={styles.achievmentModal__infoImg}
            />
          </div>
          <MainBtn onClick={onClose}>Close</MainBtn>
        </div>
      </Modal>
    </AppRoot>
  );
};

export default AchievmentModal;
